import React from 'react'
import { Box, HStack, Typography } from '@components/atom'
import * as reportCardStyles from './ReportCard.style'
import EachComponent from '@components/atom/ForEachComponent/ForEachComponent'

export interface IReportCardProps {
  name: string
  subTitle: string
  summaryInfo: { label: string; value: string }[]
  onClickCard?: () => void
}

const ReportCard = React.memo(
  ({ name, subTitle, summaryInfo, onClickCard }: IReportCardProps) => {
    return (
      <HStack sx={reportCardStyles.container} gap="48px" alignContent="center" onClick={onClickCard}>
        <Box sx={reportCardStyles.nameGroup}>
          <Typography sx={reportCardStyles.name}>{name}</Typography>
          <Typography sx={reportCardStyles.subtitle}>{subTitle}</Typography>
        </Box>
        <HStack sx={reportCardStyles.infoGroup} gap="48px">
          <EachComponent
            of={summaryInfo}
            render={(info, index) => (
              <Box key={index} maxWidth="120px">
                <Typography fontSize="15px" color="teal.600" marginBottom="8px">
                  {info.label}
                </Typography>
                <Typography fontSize="19px" color="teal.800">
                  {info.value}
                </Typography>
              </Box>
            )}
          />
        </HStack>
      </HStack>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.name === nextProps.name &&
      prevProps.subTitle === nextProps.subTitle &&
      prevProps.onClickCard === nextProps.onClickCard &&
      JSON.stringify(prevProps.summaryInfo) === JSON.stringify(nextProps.summaryInfo)
    )
  },
)

export default ReportCard
