import useMozaicSnackbar from '@hooks/useMozaicSnackbar/useMozaicSnackbar.hook'
import surveyService from '@services/SurveyService/SurveyService'
import { useMutation } from '@tanstack/react-query'

export const useDeleteSurveyMutation = ({ onSuccess }: { onSuccess: (id: any) => void }) => {
  const { enqueueSuccessSnackbar } = useMozaicSnackbar()

  const { mutateAsync: deleteSurvey } = useMutation({
    mutationKey: ['delete_survey'],
    mutationFn: surveyService.delete,
    onSuccess: (_, id) => {
      enqueueSuccessSnackbar('Successfully deleted survey')
      onSuccess?.(id)
    },
  })

  return {
    deleteSurvey,
  }
}

export default useDeleteSurveyMutation
