import _ from 'lodash'
import GeoJSON from 'ol/format/GeoJSON'
import VectorSource from 'ol/source/Vector'

import { convertCheckpointToGeoJson, convertSurveyToGeoJson } from '@utils/map.utils'

import { ACTIONS, PROJECTION } from './ProjectDetailContext.const'
import { TProjectDetailState } from './ProjectDetailState'

function ProjectDetailReducer(state: TProjectDetailState, action: any): TProjectDetailState {
  switch (action.type) {
    case ACTIONS.SET_PROJECT_DETAIL: {
      return { ...state, projectDetail: action.payload.projectDetail }
    }
    case ACTIONS.SET_LIST_SURVEYS: {
      if (_.isEmpty(action.payload.surveys)) return state
      const geoJson = convertSurveyToGeoJson(action.payload.surveys)

      // Update features on map rather than create new source
      if (state.surveyListSource) {
        state.surveyListSource?.clear()
        state.surveyListSource?.addFeatures(
          new GeoJSON().readFeatures(geoJson, {
            featureProjection: PROJECTION.WEB_MERCATOR,
          }),
        )
        return { ...state, surveys: action.payload.surveys }
      }

      const surveyListSource = new VectorSource({
        features: new GeoJSON().readFeatures(geoJson, {
          featureProjection: PROJECTION.WEB_MERCATOR,
        }),
      })

      return { ...state, surveys: action.payload.surveys, surveyListSource }
    }
    case ACTIONS.SELECT_TILE_LAYER: {
      return { ...state, selectedTileLayer: action.payload.selectedTileLayer }
    }
    case ACTIONS.SET_PROJECT_SURVEY_CHECKPOINTS: {
      const { projectSurveyCheckpoints, surveyId, selectedCheckpointId } = action.payload
      if (_.isEmpty(projectSurveyCheckpoints)) return state

      const geoJson = convertCheckpointToGeoJson(projectSurveyCheckpoints, selectedCheckpointId)
      const vectorSource = state.checkpointsSource?.[surveyId]

      if (vectorSource) {
        vectorSource.clear()
        vectorSource.addFeatures(
          new GeoJSON().readFeatures(geoJson, {
            featureProjection: PROJECTION.WEB_MERCATOR,
          }),
        )
        return {
          ...state,
          projectSurveyCheckpoints: {
            ...state.projectSurveyCheckpoints,
            [surveyId]: projectSurveyCheckpoints,
          },
        }
      }
      const checkpointsSource = new VectorSource({
        features: new GeoJSON().readFeatures(geoJson, {
          featureProjection: PROJECTION.WEB_MERCATOR,
        }),
      })
      return {
        ...state,
        checkpointsSource: {
          ...state.checkpointsSource,
          [action.payload.surveyId]: checkpointsSource,
        },
        projectSurveyCheckpoints: {
          ...state.projectSurveyCheckpoints,
          [action.payload.surveyId]: projectSurveyCheckpoints,
        },
      }
    }
    case ACTIONS.SET_METRICS: {
      return { ...state, metrics: action.payload.metrics }
    }
    case ACTIONS.SET_OBSERVATIONS: {
      return {
        ...state,
        observations: {
          ...state.observations,
          [action.package.surveyId]: action.package.observations,
        },
      }
    }
    case ACTIONS.SET_MAP_RENDER_COMPLETE: {
      return {
        ...state,
        mapState: {
          ...state.mapState,
          initialized: action.payload.initialized,
        },
      }
    }
    case ACTIONS.SET_SELECTED_TILE: {
      const { selectedTileLayer } = action.payload
      return {
        ...state,
        selectedTileLayer,
      }
    }
    case ACTIONS.SET_TILE_LAYERS: {
      return {
        ...state,
        tileLayers: action.payload.tileLayers,
      }
    }
    case ACTIONS.SET_EVENTS: {
      return {
        ...state,
        events: {
          ...state.events,
          ...action.payload,
        },
      }
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}

export default ProjectDetailReducer
