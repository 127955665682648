import { ISurvey } from '@models/survey.model'
import mapFeatureService from '@services/MapFeatureService/MapFeatureService'
import { ICreateMapFeature } from '@services/MapFeatureService/MapFeatureService.model'
import surveyService from '@services/SurveyService/SurveyService'
import { ICreateNewSurvey } from '@services/SurveyService/SurveyService.model'
import { useMutation } from '@tanstack/react-query'

export const useCreateSurveyMutation = ({ onSuccess }: { onSuccess?: (data: ISurvey) => void } = {}) => {
  const { mutateAsync: createMapFeatureAsync } = useMutation({
    mutationKey: ['create_map_feature'],
    mutationFn: mapFeatureService.create,
  })

  const { mutateAsync: createSurveyWithNewMapFeature, isPending: createSurveyWithNewMapFeatureLoading } = useMutation({
    mutationKey: ['create_survey_with_new_map_feature'],
    mutationFn: ({
      payloadMapFeature,
      payloadSurvey,
    }: {
      payloadMapFeature: ICreateMapFeature
      payloadSurvey: Omit<ICreateNewSurvey, 'mapFeatureId'>
    }) => {
      return createMapFeatureAsync(payloadMapFeature).then(res => {
        const { data: mapFeature } = res
        return surveyService.create({
          ...payloadSurvey,
          mapFeatureId: mapFeature?.id,
        })
      })
    },
    onSuccess: data => {
      if (data?.data) {
        onSuccess?.(data?.data)
      }
    },
  })

  const { mutateAsync: createSurvey, isPending: createSurveyLoading } = useMutation({
    mutationKey: ['create_survey_with_selected_map_feature'],
    mutationFn: surveyService.create,
    onSuccess: data => {
      if (data?.data) {
        onSuccess?.(data?.data)
      }
    },
  })

  return {
    createSurveyWithNewMapFeature,
    createSurvey,
    isLoading: createSurveyWithNewMapFeatureLoading || createSurveyLoading,
    createMapFeatureAsync,
  }
}

export default useCreateSurveyMutation
