import { useTheme } from '@mui/material'

import { Box, Button, TextField } from '@components/atom'
import Icons from '@components/atom/Icons'
import { IconAddNote } from '@icons/index'
import * as styles from './CompactNoteInput.styles'

export type CompactNoteInputProps = {
  showNoteInput: boolean
  setShowNoteInput: (show: boolean) => void
  note: string
  handleNoteChange: (value: string) => void
  onSaveNote?: (vale: string) => void
}

export const CompactNoteInput = ({ showNoteInput, setShowNoteInput, note, handleNoteChange, onSaveNote }: CompactNoteInputProps) => {
  const { palette } = useTheme()

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && onSaveNote) {
      onSaveNote(note)
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      {!showNoteInput ? (
        <Button
          variant="text"
          color="primary"
          size="small"
          sx={{ height: '24px', ml: 'auto' }}
          startIcon={<Icons width={20} height={20} Icon={IconAddNote} color={palette.grey[500]} />}
          onClick={() => setShowNoteInput(!showNoteInput)}
        >
          {!showNoteInput && (note ? 'Edit note' : 'Add a note')}
        </Button>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, gap: '4px' }}>
          <Button
            variant="text"
            sx={{ py: 0, px: 1, minWidth: 'auto' }}
            startIcon={<Icons width={20} height={20} Icon={IconAddNote} color={palette.grey[500]} />}
            onClick={() => setShowNoteInput(!showNoteInput)}
          />
          <TextField
            sx={{ ...styles.textFieldForm, flex: 1, width: '100%', alignSelf: 'center' }}
            size="small"
            autoFocus
            label="Label"
            placeholder="Add a note..."
            type="text"
            fullWidth
            value={note}
            inputProps={{ autoComplete: 'off' }}
            onKeyDown={handleKeyDown}
            onChange={event => handleNoteChange(event.target.value)}
          />
          {onSaveNote && (
            <Button onClick={() => onSaveNote(note)} sx={styles.saveButton}>
              Save
            </Button>
          )}
        </Box>
      )}
    </Box>
  )
}

export default CompactNoteInput
