import dayjs from 'dayjs'

export const defaultDateFormat = (date?: Date | string) => {
  if (!date) return ''

  const parsedDate = typeof date === 'string' ? new Date(date) : date

  return dayjs(parsedDate).format('DD MMMM YYYY')
}

export const compactDateFormat = (date?: Date | string) => {
  if (!date) return ''

  const parsedDate = typeof date === 'string' ? new Date(date) : date

  return dayjs(parsedDate).format('DD/MM/YY')
}
