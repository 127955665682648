import { createContext } from 'react'
import { TAuthState } from './AuthenticationState'

interface AuthenticationContextType {
  state: TAuthState
  dispatch: React.Dispatch<any>
}

const AuthenticationContext = createContext<AuthenticationContextType | null>(null)

export default AuthenticationContext
