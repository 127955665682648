import { useMutation, useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { Feature, ImageTile, Map, View } from 'ol'
import { Geometry } from 'ol/geom'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { OSM, XYZ } from 'ol/source'
import VectorSource from 'ol/source/Vector'
import { apply } from 'ol-mapbox-style'
import { fromLonLat } from 'ol/proj'
import React, { ReactNode, useReducer, useRef } from 'react'
import useCreateSurveyMutation from '@hooks/mutation/useCreateSurveyMutation/useCreateSurveyMutation'
import useAppParams from '@hooks/useAppParams'
import { ESurveyState } from '@models/survey.model'
import checkpointService from '@services/CheckpointService/CheckpointService'
import observationService from '@services/ObservationService/ObservationService'
import projectService from '@services/ProjectService/ProjectService'
import surveyService from '@services/SurveyService/SurveyService'
import { getTileUrl, isLayerExist, zoomToSource } from '@utils/open-layer'
import { styleFunctionCheckpoints, styleFunctionSurveys } from '@utils/open-layer/open-layer.styles'
import tileLayerService from '@services/TileLayerService/TileLayerService'
import logger from '@utils/logger'
import useDeleteSurveyMutation from '@hooks/mutation/useCreateSurveyMutation/useDeleteSurveyMutation'
import ENV from '@configs/env'

import ProjectDetailContext from './ProjectDetailContext'
import { ACTIONS, ZIndex } from './ProjectDetailContext.const'
import reducer from './ProjectDetailReducer'
import { defaultState, TProjectDetailState } from './ProjectDetailState'
import { selectSurveyDetailBySurveyId } from './hooks/useProjectDetailSelector'
import { useMozaicSnackbar } from '@hooks/index'

interface ProjectDetailProviderProps {
  children: ReactNode
}

export type VectorLayerName = 'surveys' | 'checkpoints' | 'createSurvey' | 'editSurvey'
export type TileLayerName = 'tiles'
export type LayerName = VectorLayerName | TileLayerName

interface AddFeaturesLayerProps {
  name: VectorLayerName
  source: VectorSource<Feature<Geometry>>
  shouldZoomToSource?: boolean
}

export interface ProjectDetailContextType {
  /**
   * Maps
   */
  state: TProjectDetailState
  dispatch: React.Dispatch<any>
  mapRef: React.MutableRefObject<HTMLDivElement | null>
  mapInstanceRef: React.MutableRefObject<Map | null>
  layersRef: React.MutableRefObject<Record<LayerName, VectorLayer<VectorSource<any>>>>
  /**
   * Fetching and Update data
   */
  fetchMetrics: () => void
  isLoadingMetrics: boolean
  fetchProjectDetail: () => void
  fetchSurveys: (metricId?: any) => Promise<any>
  isLoadingSurveys: boolean
  fetchObservations: () => void
  isLoadingObservations: boolean
  updateSurveyState: (state: any) => Promise<any>
  updateSurvey: (state: any) => Promise<any>
  isLoadingUpdateSurvey: boolean
  fetchCheckpointLocations: () => void
  createSurveyWithNewMapFeature: any
  createSurvey: any
  deleteSurvey: any
  isLoadingCreateNewSurvey: boolean
  refetchSurveyDetail: () => void
  isLoadingSurveyDetail: boolean
  isLoadingCheckpoints: boolean
  /**
   * Map handler
   */
  initMap: () => Map | undefined
  addVectorLayer: (props: AddFeaturesLayerProps) => VectorLayer<VectorSource<any>> | undefined

  /** Tile layers */
  addTilesLayer: (folderPath: string, bucketId: string, name: TileLayerName) => void
  handleUpdateTileLayer: (folderPath: string | null) => void
  hideTilesLayer: () => void
  fetchTiles: () => void
}

const ProjectDetailProvider: React.FC<ProjectDetailProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState)
  const mapRef = useRef<HTMLDivElement | null>(null)
  const mapInstanceRef = useRef<Map | null>(null)
  const { projectId, getParams, checkpointId } = useAppParams()
  const { enqueueSuccessSnackbar } = useMozaicSnackbar()

  const tileLayerQuery = useQuery({
    queryKey: ['tile-layers', projectId],
    queryFn: () =>
      tileLayerService.fetchTileLayersByProjectId(projectId).then(({ data }) => {
        dispatch({
          type: ACTIONS.SET_TILE_LAYERS,
          payload: { tileLayers: data },
        })
        return data
      }),
    refetchOnMount: false,
    enabled: false,
  })

  const surveyDetailQuery = useQuery({
    queryKey: ['surveyDetail'],
    queryFn: ({ queryKey: [_] }) => {
      const surveyId = getParams('surveyId')
      return surveyService.fetchSurveyDetail({ surveyId: surveyId as string })
    },
    enabled: false,
    refetchOnMount: false,
  })

  // Manage added layers, check existed layer in map.
  const layersRef = useRef<{ surveys: any; checkpoints: any; createSurvey: any; editSurvey: any; tiles: any }>({
    surveys: null,
    checkpoints: null,
    createSurvey: null,
    editSurvey: null,
    tiles: null,
  })

  const MetricQuery = useQuery({
    queryKey: ['fetch-metrics-by-project-id', projectId],
    queryFn: () =>
      projectService.fetchMetricsByProjectId(projectId).then(({ data }) => {
        dispatch({ type: ACTIONS.SET_METRICS, payload: { metrics: data ?? [] } })
        return data
      }),
    enabled: false,
    refetchOnMount: false,
  })

  const projectDetailQuery = useQuery({
    queryKey: ['fetch-project-detail', projectId],
    queryFn: () =>
      projectService.fetchProjectDetail(projectId).then(({ data }) => {
        dispatch({
          type: ACTIONS.SET_PROJECT_DETAIL,
          payload: { projectDetail: data },
        })
        return data
      }),
    enabled: false,
    refetchOnMount: false,
  })

  const surveysQuery = useMutation({
    mutationKey: ['fetch-surveys-by-project-id', projectId],
    mutationFn: metricId =>
      surveyService.fetchSurveys({ projectId: projectId as string, metricId: metricId as any }).then(({ data }) => {
        dispatch({
          type: ACTIONS.SET_LIST_SURVEYS,
          payload: { surveys: data },
        })
        return data
      }),
  })

  const tilesMutation = useMutation({
    mutationKey: ['fetch-image-tiles'],
    mutationFn: ({ src, tile }: { src: string; tile: ImageTile }) =>
      !tile ? Promise.reject('Missing tile in mutation variables') : tileLayerService.fetchTileImage(src),
    onSuccess(imageUrl, variables) {
      try {
        const imageElement = variables.tile.getImage() as HTMLImageElement
        if (imageElement) {
          imageElement.src = imageUrl
        }
      } catch (error) {
        logger.error(`Failed to set tile image src`, error)
      }
    },
  })

  const observationsQuery = useQuery({
    queryKey: ['fetch-observations-by-survey-id', projectId],
    queryFn: ({ queryKey }) => {
      const surveyId = getParams('surveyId')

      if (!surveyId) {
        return Promise.reject()
      }
      return observationService.fetchLinkedObservations({ projectId: queryKey[1] ?? '', surveyId }).then(({ data }) => {
        dispatch({
          type: ACTIONS.SET_OBSERVATIONS,
          package: {
            observations: data,
            surveyId: surveyId,
          },
        })
      })
    },
    enabled: false,
    refetchOnMount: false,
  })

  const checkpointsQuery = useQuery({
    queryKey: ['fetch-checkpoints-across-project', projectId, checkpointId],
    queryFn: async () => {
      const surveyId = getParams('surveyId')
      const checkpointId = getParams('checkpointId')

      if (!surveyId) {
        return Promise.reject()
      }

      const surveyDetail = selectSurveyDetailBySurveyId(state, surveyId)

      if (!surveyDetail) {
        return Promise.reject()
      }

      const { data: allCheckpoints } = await checkpointService.fetchCheckpointsAcrossProject({
        projectId: projectId ?? '',
        startDate: surveyDetail?.startDate as string,
        endDate: surveyDetail?.endDate as string,
      })

      const { data: surveyCheckpoints } = await checkpointService.fetchCheckpointsBySurveyId({
        surveyId: surveyId ?? '',
      })

      const data = allCheckpoints?.map(checkpoint => ({
        ...checkpoint,
        isLinked: _.findIndex(surveyCheckpoints, { checkpointId: checkpoint.checkpointId }) > -1,
      }))
      dispatch({
        type: ACTIONS.SET_PROJECT_SURVEY_CHECKPOINTS,
        payload: { projectSurveyCheckpoints: data, surveyId, selectedCheckpointId: checkpointId },
      })
      return data
    },
    enabled: false,
    refetchOnMount: false,
  })

  const {
    createSurveyWithNewMapFeature,
    createSurvey,
    isLoading: isLoadingCreateNewSurvey,
  } = useCreateSurveyMutation({
    onSuccess: data => {
      enqueueSuccessSnackbar('Survey was created successfully!')
      dispatch({
        type: ACTIONS.SET_LIST_SURVEYS,
        payload: {
          surveys: [...state.surveys, data],
        },
      })
    },
  })

  const updateSurveyStateMutation = useMutation({
    mutationKey: ['update-survey-state'],
    mutationFn: (state: ESurveyState) => {
      const surveyId = getParams('surveyId')
      return surveyService.updateSurveyState({ id: surveyId as string, state })
    },
    onSuccess: ({ data }) => {
      const updatedSurveys = state.surveys.map(survey => (survey.id === data?.id ? { ...survey, ...data } : survey))
      dispatch({
        type: ACTIONS.SET_LIST_SURVEYS,
        payload: {
          surveys: [...updatedSurveys],
        },
      })
    },
  })

  const updateSurveyMutation = useMutation({
    mutationKey: ['update_survey_with_selected_map_feature'],
    mutationFn: surveyService.update,
    onSuccess: ({ data }) => {
      const updatedSurveys = state.surveys.map(survey => (survey.id === data?.id ? { ...survey, ...data } : survey))
      dispatch({
        type: ACTIONS.SET_LIST_SURVEYS,
        payload: {
          surveys: [...updatedSurveys],
        },
      })
    },
  })

  const { deleteSurvey } = useDeleteSurveyMutation({
    onSuccess: id => {
      const updatedSurveys = state.surveys.filter(survey => survey.id !== id)
      dispatch({
        type: ACTIONS.SET_LIST_SURVEYS,
        payload: {
          surveys: [...updatedSurveys],
        },
      })
    },
  })

  const initMap = () => {
    if (!mapRef.current || mapInstanceRef.current) return

    const map = new Map({
      target: mapRef.current,
      layers: [new TileLayer({ source: new OSM() })],
      view: new View({
        center: fromLonLat([-2, 24]),
        zoom: 20,
      }),
      controls: [],
    })

    apply(map, `${ENV.MAP_STYLE}?key=${ENV.MAP_KEY}`)

    mapInstanceRef.current = map

    dispatch({
      type: ACTIONS.SET_MAP_RENDER_COMPLETE,
      payload: {
        initialized: true,
      },
    })

    return map
  }

  const addVectorLayer = ({ shouldZoomToSource = false, name, source }: AddFeaturesLayerProps) => {
    if (!mapInstanceRef.current) return

    const exist = isLayerExist(mapInstanceRef.current, layersRef.current[name])
    const layerStyles: Record<VectorLayerName, any> = {
      surveys: styleFunctionSurveys,
      checkpoints: styleFunctionCheckpoints,
      createSurvey: styleFunctionSurveys,
      editSurvey: styleFunctionSurveys,
    }
    let layer

    if (exist) {
      layersRef.current[name].setSource(source)
      layer = layersRef.current[name]
      if (shouldZoomToSource) {
        zoomToSource(mapInstanceRef.current, source)
      }
    } else {
      layer = new VectorLayer({
        source: source,
        style: layerStyles[name],
        zIndex: ZIndex[name],
      })
      mapInstanceRef.current.addLayer(layer)
      layersRef.current[name] = layer
    }

    if (shouldZoomToSource) {
      zoomToSource(mapInstanceRef.current, source)
    }

    return layer
  }

  const addTilesLayer = async (folderPath: string, bucketId: string, name: TileLayerName) => {
    if (!mapRef.current) return
    const exist = isLayerExist(mapInstanceRef.current, layersRef.current[name])
    let tileLayer

    if (exist) {
      tileLayer = layersRef.current[name]
      tileLayer.setVisible(true)
    } else {
      tileLayer = new TileLayer({
        source: new XYZ({
          tileUrlFunction: tileCoord => getTileUrl(tileCoord, bucketId, folderPath),
          tileLoadFunction: (imageTile, src) => {
            const tile = imageTile as ImageTile
            tilesMutation.mutate({ src, tile })
          },
        }),
      })
      mapInstanceRef.current?.addLayer(tileLayer)
      layersRef.current[name] = tileLayer
    }
  }

  const hideTilesLayer = () => {
    if (!mapInstanceRef.current) return

    const tileLayer = layersRef.current['tiles']

    if (tileLayer) {
      tileLayer.setVisible(false)
    } else {
      console.warn(`No Tiles layer found to remove.`)
    }
  }

  const handleUpdateTileLayer = (folderPath: string | null) => {
    dispatch({
      type: ACTIONS.SET_SELECTED_TILE,
      payload: {
        selectedTileLayer: folderPath,
      },
    })
  }

  return (
    <ProjectDetailContext.Provider
      value={{
        state,
        dispatch,
        mapRef,
        mapInstanceRef,
        layersRef,
        /**
         * Fetch & update data
         */
        fetchMetrics: MetricQuery.refetch,
        isLoadingMetrics: MetricQuery.isLoading || MetricQuery.isRefetching,
        fetchProjectDetail: projectDetailQuery.refetch,
        fetchSurveys: surveysQuery.mutateAsync,
        isLoadingSurveys: surveysQuery.isPending,
        fetchObservations: observationsQuery.refetch,
        isLoadingObservations: observationsQuery.isLoading,
        updateSurveyState: updateSurveyStateMutation.mutateAsync,
        updateSurvey: updateSurveyMutation.mutateAsync,
        isLoadingUpdateSurvey: updateSurveyMutation.isPending,
        fetchCheckpointLocations: checkpointsQuery.refetch,
        createSurveyWithNewMapFeature,
        createSurvey,
        deleteSurvey,
        isLoadingCreateNewSurvey,
        refetchSurveyDetail: surveyDetailQuery.refetch,
        isLoadingSurveyDetail: surveyDetailQuery.isLoading,
        isLoadingCheckpoints: checkpointsQuery.isLoading,
        /**
         * Map handler
         */
        initMap,
        addVectorLayer,
        /**
         * Tile layers
         */
        addTilesLayer,
        hideTilesLayer,
        handleUpdateTileLayer,
        fetchTiles: tileLayerQuery.refetch,
      }}
    >
      {children}
    </ProjectDetailContext.Provider>
  )
}

export default ProjectDetailProvider
