import tinycolor from 'tinycolor2'

const baseColor = '#082F4A'

const MozaicEarthBlue = {
  50: tinycolor(baseColor).lighten(40).toHexString(),
  100: '#BFE1F8',
  200: tinycolor(baseColor).lighten(20).toHexString(),
  300: tinycolor(baseColor).lighten(10).toHexString(),
  400: 'rgb(23, 136, 212)',
  500: tinycolor(baseColor).lighten(2.5).toHexString(),
  600: tinycolor(baseColor).darken(2.5).toHexString(),
  700: tinycolor(baseColor).darken(5).toHexString(),
  800: baseColor,
  900: tinycolor(baseColor).darken(10).toHexString(),
  A100: tinycolor(baseColor).lighten(50).toHexString(),
  A200: tinycolor(baseColor).lighten(40).toHexString(),
  A400: tinycolor(baseColor).lighten(30).toHexString(),
  A700: tinycolor(baseColor).lighten(20).toHexString(),
}

export default MozaicEarthBlue
