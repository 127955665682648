import { SxProps } from '@mui/material'

export const LegendCategoryContainer: SxProps = {
  background: 'white',
  border: '1px solid black',
  borderRadius: '5px',
  padding: '10px',
  width: 'fit-content',
}

export const LegendCategory: SxProps = {
  alignItems: 'center',
  marginBottom: '5px',
}

export const ChipCategory = (color: string) => ({
  width: 20,
  height: 20,
  borderRadius: '50%',
  backgroundColor: color,
  marginRight: 1,
})
