import { useContext, useMemo } from 'react'
import { TAuthState } from './AuthenticationState'
import AuthenticationContext from './AuthenticationContext'

const useAuthenticationSelector = <T>(selector: (state: TAuthState) => T): T => {
  const context = useContext(AuthenticationContext)

  if (!context) {
    throw new Error('useAuthenticationSelector must be used within a AuthenticationProvider')
  }

  const { state } = context

  return useMemo(() => selector(state), [state, selector])
}

export default useAuthenticationSelector
