import ENV from '@configs/env'
import { UserbackProvider } from '@userback/react'
import React from 'react'
import { useAuthenticationSelector } from './AuthenticationContext'

const CustomUserbackProvider = ({ children }: { children: React.ReactNode }) => {
  const user = useAuthenticationSelector(state => state.user)

  return (
    <UserbackProvider token={ENV.USERBACK_KEY} options={{ email: user?.email, name: user?.id }}>
      {children}
    </UserbackProvider>
  )
}

export default CustomUserbackProvider
