import { useContext, useMemo } from 'react'
import { TProjectDetailState } from '../ProjectDetailState'
import ProjectDetailContext from '../ProjectDetailContext'
import { ISurvey } from '@models/survey.model'
import _ from 'lodash'

export const selectObservationBySurveyId = (state: TProjectDetailState, surveyId: ISurvey['id']) => state.observations[surveyId]
export const selectCheckpointLocationBySurveyId = (state: TProjectDetailState, surveyId: ISurvey['id']) =>
  state.projectSurveyCheckpoints[surveyId]

export const selectCheckpointsSourceBySurveyId = (state: TProjectDetailState, surveyId: ISurvey['id']) =>
  state.checkpointsSource?.[surveyId]

export const selectSurveyDetailBySurveyId = (state: TProjectDetailState, surveyId: ISurvey['id']) => _.find(state.surveys, { id: surveyId })

const useProjectDetailSelector = <T>(selector: (state: TProjectDetailState) => T): T => {
  const context = useContext(ProjectDetailContext)

  if (!context) {
    throw new Error('useProjectDetailSelector must be used within a ProjectDetailProvider')
  }

  const { state } = context

  return useMemo(() => selector(state), [state, selector])
}

export default useProjectDetailSelector
