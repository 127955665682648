import { IBaseResponse } from '@models/common.model'
import { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'
import { v4 as uuidv4 } from 'uuid'
import { ICreateMapFeature, IUpdateMapFeature } from './MapFeatureService.model'
import { IMapFeature } from '@models/mapFeature.model'

export class MapFeatureService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  fetchMapFeature = async (id: string): Promise<IBaseResponse<IMapFeature>> => {
    try {
      const response = await this.client.from('map_features').select('*').eq('id', id).single()
      if (response.error) {
        return { data: null, error: response?.error }
      }
      return {
        data: this.toCamelCase(response.data),
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  fetchMapFeatures = async (ids: string[]): Promise<IBaseResponse<IMapFeature[]>> => {
    try {
      const response = await this.client.from('map_features').select('*').in('id', ids)
      if (response.error) {
        return { data: [], error: response?.error }
      }
      const mapFeatures = response.data.map(feature => this.toCamelCase(feature))
      return {
        data: mapFeatures,
        error: null,
      }
    } catch (error: any) {
      return { data: [], error }
    }
  }

  create = async (request: ICreateMapFeature): Promise<IBaseResponse<IMapFeature>> => {
    try {
      const query = this.client
        .from('map_features')
        .insert({
          id: request?.id ?? uuidv4(),
          geometry: request.geometry,
          project_id: request.projectId,
          is_mission_feature: false,
          is_project_boundary: false,
          layer_key: request.layerKey,
        })
        .select('*')
        .single()

      const { data, error } = await query

      if (error) {
        return { data: null, error: error }
      }

      return {
        data: this.toCamelCase(data),
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  update = async (request: IUpdateMapFeature): Promise<IBaseResponse<IMapFeature>> => {
    try {
      const query = this.client
        .from('map_features')
        .update({
          geometry: request.geometry,
        })
        .eq('id', request.id)
        .select('*')
        .single()
      const { data, error } = await query

      if (error) {
        return { data: null, error: error }
      }

      return {
        data: this.toCamelCase(data),
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }
}

const mapFeatureService = new MapFeatureService()

export default mapFeatureService
