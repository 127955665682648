import { Chip, HStack, Typography, VStack } from '@components/atom'
import EachComponent from '@components/atom/ForEachComponent/ForEachComponent'
import * as styles from './LegendCategory.styles'
import { memo } from 'react'

export type LegendCategoryProps = {
  categoryValues: string[]
}

export const LegendCategory = memo(({ categoryValues }: LegendCategoryProps) => {
  return (
    <VStack sx={styles.LegendCategoryContainer}>
      <EachComponent
        of={categoryValues}
        render={(category: string, index) => {
          const color = `hsl(${(index / categoryValues.length) * 360}, 70%, 60%)`
          return (
            <HStack key={category} sx={styles.LegendCategory}>
              <Chip size="small" sx={styles.ChipCategory(color)} />
              <Typography>{category}</Typography>
            </HStack>
          )
        }}
      />
    </VStack>
  )
})

export default LegendCategory
