import { IMapFeature } from './mapFeature.model'

export interface MetricAttribute {
  name: string
  value: string
  unit: string
  description?: string
}

interface MetricIndicator {
  name: string
  value: string
  unit: string
  description?: string
}

interface TableColumn {
  key: string
  displayName: string
}

interface TableData {
  [key: string]: string
}

interface Table {
  columnOrder: TableColumn[]
  data: TableData[]
}

export interface ReportMapData extends Record<string, any> {
  mapFeatureId: string
  mapFeature?: IMapFeature
}

export interface ReportMapDisplayOption {
  key: string
  type: string
  display: string
}

export interface ReportMap {
  data?: ReportMapData[]
  displayOptions?: ReportMapDisplayOption[]
}

export interface ResultDetailMetric {
  metricId: number
  name: string
  description: string
  metricAttributes: MetricAttribute[]
  metricIndicators: MetricIndicator[]
  table: Table
  map?: ReportMap
}

export interface ResultDetailData {
  metrics: ResultDetailMetric[]
}

export enum EStylingType {
  Gradient = 'gradient',
  Category = 'category',
}
