import { EObservationState } from '@models/observation.model'
import { INote, IPoint } from '@models/point.model'
import React, { createContext } from 'react'
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'
import { TCheckpointOverviewState } from './CheckpointOverviewState'

type CheckpointOverviewContextType = {
  state: TCheckpointOverviewState
  dispatch: React.Dispatch<any>
  fetchInitData: () => void
  isLoadingObservations: boolean
  fetchObservations: (params: { checkpointId: string }) => Promise<any>
  updateStateObservation: (params: { observationId: string; state: EObservationState }) => Promise<any>
  linkToSurvey: (observationId: string) => Promise<any>
  unLinkToSurvey: (observationId: string) => Promise<any>
  transformRef: React.RefObject<ReactZoomPanPinchRef | null>
  handleCreateAnnotationByPoint: (point: IPoint) => Promise<void>
  handleCreateNoteAnnotation: (note: INote) => Promise<void>
  handleUpdateNoteAnnotation: (note: INote) => Promise<void>
  handleRemoveAnnotationByPoint: (pointId: string) => Promise<void>
  handleRemoveAnnotationByNote: (noteId: string) => Promise<void>
  refetchObservations: () => void
  // Labels
  fetchAllLabels: () => Promise<any>
  loadingLabels: boolean
  refetchLabels: () => Promise<any>
}

export const CheckpointOverviewContext = createContext<CheckpointOverviewContextType | null>(null)

CheckpointOverviewContext.displayName = 'CheckpointOverviewContext'

export default CheckpointOverviewContext
