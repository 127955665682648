import {
  AnnotationDto,
  labelAnnotationRequestCreate,
  NoteAnnotationDto,
  NoteAnnotationRequestCreate,
  NoteAnnotationRequestUpdate,
} from '@models/annotation.model'
import { IPostResponse } from '@models/common.model'
import { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'
import _ from 'lodash'

export class AnnotationService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  createNewLabelAnnotation = async (request: labelAnnotationRequestCreate): Promise<IPostResponse<AnnotationDto>> => {
    try {
      const { data: userData } = await this.client.auth.getUser()
      request.created_by = userData?.user?.id ?? ''
      const response = await this.client.from('annotations').insert(request).select('*')
      if (response.error) {
        return { data: null, success: false, error: response?.error }
      }
      return { data: new AnnotationDto(_.first(response.data)), success: true, error: null }
    } catch (error: any) {
      return { data: null, success: false, error: error }
    }
  }

  createNoteAnnotation = async (request: NoteAnnotationRequestCreate): Promise<IPostResponse<AnnotationDto>> => {
    try {
      const { data: userData } = await this.client.auth.getUser()
      request.created_by = userData?.user?.id ?? ''
      const response = await this.client.from('annotations').insert(request).select('*')
      if (response.error) {
        return { data: null, success: false, error: response?.error }
      }
      return { data: new NoteAnnotationDto(_.first(response.data)), success: true, error: null }
    } catch (error: any) {
      return { data: null, success: false, error: error }
    }
  }

  updateNoteAnnotation = async (request: NoteAnnotationRequestUpdate): Promise<IPostResponse<AnnotationDto>> => {
    try {
      const response = await this.client.from('annotations').update(request).eq('id', request.id).select('*')
      if (response.error) {
        return { data: null, success: false, error: response?.error }
      }
      return { data: new NoteAnnotationDto(_.first(response.data)), success: true, error: null }
    } catch (error: any) {
      return { data: null, success: false, error: error }
    }
  }

  removeLabelAnnotationById = async (annotationId: string): Promise<IPostResponse<null>> => {
    try {
      const response = await this.client.from('annotations').delete().eq('id', annotationId)
      if (response.error) {
        return { data: null, success: false, error: response?.error }
      }
      return { data: null, success: true, error: null }
    } catch (error: any) {
      return { data: null, success: false, error: error }
    }
  }
}

// Singleton pattern
const annotationService = new AnnotationService()

export default annotationService
