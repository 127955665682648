import { EFormSubmissionState } from '@models/formSubmission.model'
import { EObservationState } from '@models/observation.model'
import { ESurveyState } from '@models/survey.model'

export interface StateOptions<T> {
  label: string
  value: T
}

export const OBSERVATION_STATE_OPTIONS: StateOptions<EObservationState>[] = [
  {
    label: 'Open',
    value: EObservationState.Open,
  },
  {
    label: 'Active',
    value: EObservationState.InProgress,
  },
  {
    label: 'Done',
    value: EObservationState.Done,
  },
]

export const SURVEY_STATE_OPTIONS: StateOptions<ESurveyState>[] = [
  {
    label: 'Open',
    value: ESurveyState.Open,
  },
  {
    label: 'Active',
    value: ESurveyState.InProgress,
  },
  {
    label: 'Done',
    value: ESurveyState.Done,
  },
]

export const SURVEY_FORM_STATE_OPTIONS: StateOptions<EFormSubmissionState>[] = [
  {
    label: 'Open',
    value: EFormSubmissionState.Open,
  },
  {
    label: 'Done',
    value: EFormSubmissionState.Complete,
  },
]

export const SELECT_OPTIONS = {
  OBSERVATION_STATE_OPTIONS,
  SURVEY_STATE_OPTIONS,
  SURVEY_FORM_STATE_OPTIONS,
}
