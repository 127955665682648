import { useMemo } from 'react'
import { Render, Stack, VStack } from '@components/atom'
import { calculateGradientValues } from '@utils/legend'
import * as styles from './LegendGradient.styles'
import { LegendGradientContainer } from './LegendGradient.styles'
import { ReportMapData } from '@models/reportResultDetail.model'

export type LegendGradientProps = {
  stylingOption: string
  ReportMapData: ReportMapData[]
}

export const LegendGradient = ({ stylingOption, ReportMapData }: LegendGradientProps) => {
  const {
    minValue = 0,
    maxValue = 0,
    midValue = 0,
  } = useMemo(() => calculateGradientValues(ReportMapData, stylingOption), [stylingOption, ReportMapData])

  return (
    <LegendGradientContainer>
      <VStack sx={styles.Gradient}>
        <Stack sx={styles.MinValue}>{minValue?.toFixed(0)}</Stack>
        <Render test={midValue !== null} truthy={<Stack sx={styles.MidValue}>{midValue}</Stack>}></Render>
        <Stack sx={styles.MaxValue}>{maxValue?.toFixed(0)}</Stack>
      </VStack>
    </LegendGradientContainer>
  )
}

export default LegendGradient
