import React, { ReactNode, useReducer } from 'react'
import AuthenticationContext from './AuthenticationContext'
import reducer from './AuthenticationReducer'

interface AuthenticationProviderProps {
  children: ReactNode
}

const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {})

  return (
    <AuthenticationContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationProvider
