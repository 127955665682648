import ENV from '@configs/env'
import { Box, Chip } from '@mui/material'
import { useState } from 'react'

export const AppVersion = () => {
  const [display, setDisplay] = useState(ENV.ENV !== 'prod')

  if (!display) return null
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '16px',
        right: '70px',
        zIndex: 9999,
      }}
    >
      <Chip label={`${ENV.APP_VERSION ?? ''} / ${ENV.BUILD_DATETIME ?? ''}`} onDelete={() => setDisplay(false)} />
    </Box>
  )
}

export default AppVersion
