import { SxProps } from '@mui/material'

export const brandLogoContainer = (onClick?: () => void): SxProps => ({
  padding: '0 8px',
  gap: '10px',
  alignItems: 'center',
  cursor: onClick ? 'pointer' : 'inherit',
})

export const brandLogoTextContainer = (isBold: boolean): SxProps => ({
  fontSize: '1.125rem',
  lineHeight: '28px',
  fontWeight: isBold ? 600 : 400,
})
