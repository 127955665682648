import { ObservationMedia } from './observationMedia.model'

export interface IObservation {
  checkpointId: string
  altitude: number | null
  altitudeAccuracy: number | null
  createdAt: string
  deletedAt: string | null
  id: string
  location: string | null
  locationAccuracy: number | null
  locationIsMocked: boolean | null
  locationTimestamp: string | null
  mediaGuide: string
  missionId: string
  observationDate: string
  observationKey: string
  projectId: string
  taskId: string
  updatedAt: string
  userId: string
  mediaList: ObservationMedia[]
  surveysObservations: ISurveysObservation[]
  mediaGuides: IMediaGuideAttributes | null
  checkpointLocations: {
    checkpointKey: string
  }

  /**
   * Additional field to indicate if the observation is linked to a Survey
   */
  state?: ISurveysObservation['state']
  isLinkedToSurvey?: boolean
}

export enum EObservationState {
  Open = 'open',
  InProgress = 'in_progress',
  Done = 'done',
}

export interface ISurveysObservation {
  state: EObservationState
  observationId: string
  surveyId: string
}

export interface IMediaGuideAttributes {
  displayName: string
}
