import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material'

const MuiButton: {
  defaultProps: ComponentsProps['MuiButton']
  styleOverrides: ComponentsOverrides<Theme>['MuiButton']
} = {
  defaultProps: {
    variant: 'contained',
    color: 'primary',
  },
  styleOverrides: {
    root: () => ({
      textTransform: 'none',
    }),
    outlined: () => ({
      backgroundColor: 'rgba(250, 250, 250, 1)',
    }),
    text: () => ({
      '&:hover': {
        background: 'transparent',
      },
    }),
  },
}

export default MuiButton
