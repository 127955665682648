import { SxProps } from '@mui/material'

export const image = (isActive: boolean): SxProps => ({
  width: 242,
  height: 141,
  borderRadius: 1,
  cursor: 'pointer',
  border: '4px solid',
  transition: 'border-color 0.1s ease-in-out',
  borderColor: isActive ? 'green.500' : 'transparent',
})
