import { useContext, useEffect, useMemo } from 'react'
import { matchPath, Outlet, useLocation } from 'react-router-dom'

import { Box, OlMap, Render } from '@components/atom'
import ProjectDetailContext from '@context/ProjectDetailContext/ProjectDetailContext'
import withProjectDetailProvider from '@context/ProjectDetailContext/withProjectDetailProvider'
import useAppParams from '@hooks/useAppParams'
import { zoomToSurveyFeature } from '@utils/open-layer'

const ProjectDetailLayout = ({ mapDisplayRoutes = [] }: { mapDisplayRoutes: string[] }) => {
  const context = useContext(ProjectDetailContext)
  const { pathname } = useLocation()
  const { surveyId, checkpointId } = useAppParams()

  if (!context) {
    return <div />
  }

  const displayMap = useMemo(() => {
    return mapDisplayRoutes.some(route => matchPath(route, pathname))
  }, [pathname])

  useEffect(() => {
    if (!context.mapInstanceRef.current) {
      context.initMap()
    } else if (context.mapRef.current && !checkpointId) {
      context.mapInstanceRef.current?.setTarget(context.mapRef.current)
      if (surveyId) {
        const surveyListSource = context.state.surveyListSource
        const selectedFeature = surveyListSource?.getFeatures().find(f => f.get('surveyId') === surveyId)
        zoomToSurveyFeature({ map: context.mapInstanceRef.current, feature: selectedFeature })
      }
    }
  }, [displayMap, surveyId, context.mapRef.current])

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Render test={displayMap} fallback={<Outlet />}>
        <OlMap ref={context.mapRef} isFullScreen>
          <Outlet />
        </OlMap>
      </Render>
    </Box>
  )
}

export default withProjectDetailProvider(ProjectDetailLayout)
