import * as turf from '@turf/turf'
import { FeatureCollection } from 'geojson'
import _ from 'lodash'

import { ISurvey } from '@models/survey.model'
import { ICheckpointLocationWithState } from '@models/checkpoint.model'
import { FeatureProperties } from './open-layer/open-layer.styles'

export const PolygonVariants = {
  yellow: {
    fill: {
      'fill-color': '#F59E0B',
      'fill-opacity': 0.25,
    },
    outlined: {
      'line-color': '#F59E0B',
      'line-width': 4,
    },
  },
  green: {
    fill: {
      'fill-color': '#10B981',
      'fill-opacity': 0.25,
    },
    outlined: {
      'line-color': '#10B981',
      'line-width': 4,
    },
  },
  white: {
    fill: {
      'fill-color': '#FFFFFF',
      'fill-opacity': 0.5,
    },
    outlined: {
      'line-color': '#FFFFFF',
      'line-width': 4,
    },
  },
  blue: {
    fill: {
      'fill-color': '#1788D4',
      'fill-opacity': 0.25,
    },
    outlined: {
      'line-color': '#1788D4',
      'line-width': 4,
    },
  },
  gray: {
    fill: {
      'fill-color': '#8D959B',
      'fill-opacity': 0.25,
    },
    outlined: {
      'line-color': '#8D959B',
      'line-width': 4,
    },
  },
  red: {
    fill: {
      'fill-color': '#F37272',
      'fill-opacity': 0.25,
    },
    outlined: {
      'line-color': '#F37272',
      'line-width': 4,
    },
  },
  lightGreen: {
    fill: {
      'fill-color': '#10B981',
      'fill-opacity': 0.25,
    },
    outlined: {
      'line-color': '#10B981',
      'line-width': 4,
    },
  },
}

export const DEFAULT_ZOOM = 15

export const MIN_ZOOM = 10

export const customPolygonPaintTransparent = {
  'fill-color': 'transparent',
  'fill-opacity': 1,
}

export const customPolygonOutlinedPaint = {
  'line-color': ['case', ['boolean', ['get', 'isSelect'], false], '#FFF', PolygonVariants.blue.outlined['line-color']],
  'line-width': 4,
}

export enum DefaultSourceId {
  PlotBoundaries = 'plot-boundaries',
  Checkpoints = 'checkpoints',
}

export enum CheckpointStates {
  UNLINKED = 'unlinked-checkpoint',
  LINKED = 'linked-checkpoint',
  SELECTED = 'selected-checkpoint',
}

// TODO: replace turf by open layer utils.
export const convertSurveyToGeoJson = (surveys: ISurvey[]): FeatureCollection | null => {
  if (_.isEmpty(surveys)) return null
  const features = surveys.map(survey => {
    const mode = survey.mapFeatures.geometry.type.includes('LineString') ? 'linestring' : 'polygon'
    return turf.feature(survey.mapFeatures.geometry as any, {
      ...survey,
      mode,
      id: survey.mapFeatures.id,
      surveyId: survey.id,
      plotKey: survey.mapFeatures.plotKey,
    })
  })
  return turf.featureCollection(features)
}

export const convertCheckpointToGeoJson = (checkpoints?: ICheckpointLocationWithState[], selectedId?: string) => {
  if (!checkpoints || _.isEmpty(checkpoints)) return null
  if (checkpoints.length === 0) return turf.featureCollection([])

  const features = checkpoints.map(checkpoint => {
    const feature = turf.feature(checkpoint?.geojson)
    feature.properties = {
      ...feature.properties,
      id: `${checkpoint?.checkpointId}`,
      mode: 'checkpoint',
      [FeatureProperties.Selected]: selectedId === checkpoint?.checkpointId,
      [FeatureProperties.Linked]: !!checkpoint.isLinked,
      [FeatureProperties.Visible]: true,
    }
    return feature
  })

  return turf.featureCollection(features)
}
