import _ from 'lodash'
import { memo, useMemo } from 'react'
import LegendCategory from '@components/molecule/LegendCategory/LegendCategory'
import LegendGradient from '@components/molecule/LegendGradient/LegendGradient'
import { getDisplayOptionByKey, mapCategoryValues } from '@utils/legend'
import { EStylingType, ReportMap } from '@models/reportResultDetail.model'

const Legend = memo(({ stylingOption, mapData }: { stylingOption: string; mapData: ReportMap }) => {
  const displayOption = useMemo(() => getDisplayOptionByKey(mapData, stylingOption), [stylingOption, mapData])

  if (!displayOption) return null

  const { type, key } = displayOption

  switch (type) {
    case EStylingType.Gradient:
      return <LegendGradient stylingOption={stylingOption} ReportMapData={mapData.data ?? []} />

    case EStylingType.Category:
      const categoryValues = mapCategoryValues(mapData.data ?? [], key)
      return <LegendCategory categoryValues={categoryValues} />

    default:
      return null
  }
})

export default Legend
