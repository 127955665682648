import { SxProps } from '@mui/material'

export const container: SxProps = {
  borderRadius: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  width: '100%',
}

export const list = (max?: number): SxProps => ({
  paddingTop: 0,
  paddingBottom: 0,
  overflow: 'auto',
  maxHeight: max !== undefined ? `${72 * max}px` : 'calc(100vh - 620px)',
})

export const skeletonWrapper: SxProps = {
  padding: 1,
}

export const header: SxProps = {
  width: '100%',
  padding: '4px 16px',
  backgroundColor: '#E4E7EC',
}

export const item = ({ isSelected }: any): SxProps => ({
  backgroundColor: isSelected ? 'blue.100' : 'white',
  cursor: 'pointer',
})
