import { LabelDto, LabelResponse } from './label.model'

export interface AnnotationResponse {
  id: string
  media_id: string
  label_id: string
  shape_type: string
  shape_points: number[]
  created_by: string
  created_at: string
  updated_at: string
  deleted_at: string
  confidence_rating: number
  note?: string
  label?: LabelResponse
}

export interface labelAnnotationRequestCreate {
  id?: string
  label_id: string
  media_id: string
  shape_type: 'point' | 'rect' | 'polygon'
  shape_points: number[]
  confidence_rating: number
  note?: string | null
  created_by?: string
}

export interface NoteAnnotationRequestCreate {
  id?: string
  media_id: string
  shape_type: 'point' | 'rect' | 'polygon' // Should be point
  shape_points: number[]
  created_by?: string
  note: string
}

export interface NoteAnnotationRequestUpdate {
  id?: string
  note: string
}

export class AnnotationDto {
  id: string
  mediaId: string
  labelId: string
  shapeType: string
  shapePoints: number[]
  createdBy: string
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  confidenceRating: number
  note?: string
  label?: LabelDto

  constructor(data: any) {
    this.id = data.id
    this.mediaId = data.media_id
    this.labelId = data.label_id
    this.shapeType = data.shape_type
    this.shapePoints = data.shape_points
    this.createdBy = data.created_by
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
    this.deletedAt = data.deleted_at
    this.confidenceRating = data.confidence_rating
    this.note = data.note
    this.label = data.label ? new LabelDto(data.label) : undefined
  }

  toJSON() {
    return {
      id: this.id,
      mediaId: this.mediaId,
      labelId: this.labelId,
      shapeType: this.shapeType,
      shapePoints: this.shapePoints,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
      confidenceRating: this.confidenceRating,
      note: this.note,
      label: this.label ? this.label.toJSON() : undefined,
    }
  }
}

export class NoteAnnotationDto extends AnnotationDto {}
