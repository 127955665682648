import { lazy } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'

import PrivateRoute from '@components/template/PrivateRoute'
import PublicRoute from '@components/template/PublicRoute'
import RootPage from '@pages/RootPage'

import ProjectDetailLayout from '@components/template/ProjectDetailLayout/ProjectDetailLayout'
import checkpointRoutes from './checkpoint.routes'
import projectsRoute from './projects.route'
import reportsRoute from './reports.route'
import ERoutePaths from './routes.path'

const LoginPage = lazy(() => import('@pages/LoginPage/LoginPage'))
const ReportPage = lazy(() => import('@pages/ReportPage/ReportPage'))
const NotFoundPage = lazy(() => import('@pages/NotfoundPage/NotFoundPage'))
const ListSurveyPage = lazy(() => import('@pages/ListSurveyPage/ListSurveyPage'))
const CreateSurveyPage = lazy(() => import('@pages/CreateSurveyPage/CreateSurveyPage'))
const EditSurveyPage = lazy(() => import('@pages/EditSurveyPage/EditSurveyPage'))
const SurveyDetailPage = lazy(() => import('@pages/SurveyDetailPage/SurveyDetailPage'))

/**
 * Public Routes
 */
const publicRoutes = [
  {
    path: ERoutePaths.Login,
    element: (
      <PublicRoute title="Mozaic Earth Portal | Login">
        <LoginPage />
      </PublicRoute>
    ),
  },
]

/**
 * Private Routes
 */
const privateRoutes = [
  {
    path: ERoutePaths.Report,
    element: (
      <PrivateRoute title="Mozaic Earth Portal | Report">
        <ReportPage />
      </PrivateRoute>
    ),
  },
]

/**
 * Project Routes
 */
const projectRoutes = [
  {
    path: ERoutePaths.ProjectDetail,
    element: (
      <ProjectDetailLayout
        mapDisplayRoutes={[
          ERoutePaths.ListSurvey,
          ERoutePaths.SurveyDetail,
          ERoutePaths.CreateSurvey,
          ERoutePaths.ListSurvey,
          ERoutePaths.EditSurvey,
        ]}
      />
    ),
    children: [
      {
        path: ERoutePaths.ListSurvey,
        element: (
          <PrivateRoute title="Mozaic Earth Portal | Project Dashboard">
            <ListSurveyPage />
          </PrivateRoute>
        ),
      },
      {
        path: ERoutePaths.SurveyDetail,
        element: (
          <PrivateRoute title="Mozaic Earth Portal | Survey">
            <SurveyDetailPage />
          </PrivateRoute>
        ),
      },
      {
        path: ERoutePaths.CreateSurvey,
        element: (
          <PrivateRoute title="Mozaic Earth Portal | Create Survey">
            <CreateSurveyPage />
          </PrivateRoute>
        ),
      },
      {
        path: ERoutePaths.EditSurvey,
        element: (
          <PrivateRoute title="Mozaic Earth Portal | Edit Survey">
            <EditSurveyPage />
          </PrivateRoute>
        ),
      },
      {
        ...checkpointRoutes.checkpointOverview,
      },
    ],
  },
]

/**
 * Main Route Configuration
 */
const routes = [
  ...publicRoutes,
  ...privateRoutes,
  {
    path: ERoutePaths.Home,
    element: <RootPage />,
    children: [
      {
        index: true,
        element: <Navigate to={ERoutePaths.Projects} replace />,
      },
      projectsRoute.projects,
      reportsRoute.reports,
    ],
  },
  ...projectRoutes,
  {
    path: '*',
    element: <NotFoundPage />,
  },
]

const router = createBrowserRouter(routes, { basename: '/' })

export default router
