import { BoxProps } from '@components/atom'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined'
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined'
import { Box, IconButton } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import * as styles from './HorizontalScrollList.styles'

export type HorizontalScrollListProps = {
  children: React.ReactNode
  sx?: BoxProps
  verticalMouseScroll?: boolean
  scrollStep?: number
  showNavigateButton?: boolean
}

const HorizontalScrollList = ({
  children,
  sx = {},
  verticalMouseScroll = false,
  scrollStep = 258,
  showNavigateButton,
}: HorizontalScrollListProps) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  const onClickNext = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft + scrollStep,
        behavior: 'smooth',
      })
    }
  }

  const onClickPrevious = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft - scrollStep,
        behavior: 'smooth',
      })
    }
  }

  const handleScroll = (event: WheelEvent) => {
    if (scrollContainerRef.current && verticalMouseScroll) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft + event.deltaY,
        behavior: 'smooth',
      })
      event.preventDefault()
    }
  }

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current
    if (scrollContainer && verticalMouseScroll) {
      scrollContainer.addEventListener('wheel', handleScroll, { passive: false })
    }

    return () => {
      if (scrollContainer && verticalMouseScroll) {
        scrollContainer.removeEventListener('wheel', handleScroll)
      }
    }
  }, [])

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        ref={scrollContainerRef}
        sx={{
          ...sx,
          ...styles.container,
        }}
      >
        {children}
      </Box>
      {showNavigateButton && (
        <IconButton sx={styles.previousButton} onClick={onClickPrevious}>
          <ArrowCircleLeftOutlinedIcon fontSize="medium" />
        </IconButton>
      )}
      {showNavigateButton && (
        <IconButton sx={styles.nextButton} onClick={onClickNext}>
          <ArrowCircleRightOutlinedIcon fontSize="medium" />
        </IconButton>
      )}
    </Box>
  )
}

export default HorizontalScrollList
