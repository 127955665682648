import { Button, ButtonGroup, Menu, MenuItem } from '@mui/material'
import React from 'react'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { StateOptions } from '@utils/MasterData'

export type StateButtonProps<T> = {
  onChange: (value: any) => void
  markAsDone: () => void
  options: StateOptions<T>[]
  disabledAll?: boolean
  state: T
  disableTickState: T
}

export const StateButton = <T,>({ onChange, options = [], disabledAll, state, markAsDone, disableTickState }: StateButtonProps<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const onClosed = () => {
    setAnchorEl(null)
  }

  const handleSelect = (value: any) => {
    onChange(value)
    onClosed()
  }

  const handleMarkAsDone = () => {
    markAsDone()
    onClosed()
  }

  return (
    <>
      <ButtonGroup variant="outlined" aria-label="Basic button group">
        {state !== disableTickState && (
          <Button disableRipple size="small" disabled={disabledAll} onClick={handleMarkAsDone}>
            <CheckRoundedIcon fontSize="small" />
          </Button>
        )}
        <Button
          sx={{
            '.MuiButton-endIcon': { marginLeft: '0px' },
            backgroundColor: 'grey.200',
          }}
          disabled={disabledAll}
          size="small"
          id="basic-button"
          variant="outlined"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {
            /**
             * As this is displaying a label it can use observation state options
             * even though the component is also used for survey states
             */
            options.find(option => option.value === state)?.label ?? '...'
          }
        </Button>
      </ButtonGroup>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClosed}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option.label}
            onClick={e => {
              e.stopPropagation()
              handleSelect(option.value)
            }}
            sx={{ fontSize: '13px' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default StateButton
