import ProjectDetailProvider from './ProjectDetailProvider'
import React, { FC } from 'react'

const withProjectDetailProvider = <P extends object>(WrappedComponent: React.ComponentType<P>): FC<P> => {
  const ComponentWithContext: FC<P> = props => (
    <ProjectDetailProvider>
      <WrappedComponent {...props} />
    </ProjectDetailProvider>
  )

  return ComponentWithContext
}

export default withProjectDetailProvider
