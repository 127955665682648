import { HStack } from '@components/atom'
import { HorizontalScrollList } from '@components/molecule'
import { ObservationImage } from '@components/organism'
import { ObservationMedia } from '@models/observationMedia.model'
import { classifyAnnotations } from '@utils/annotation'
import _ from 'lodash'
import { memo } from 'react'
import * as styles from './ObservationImageCarousel.styles'

export type ObservationImageCarouselProps = {
  data?: ObservationMedia[]
  activeId?: string
  onClick?: (item: ObservationMedia, blob: Blob) => void
  minHeight?: number
}

export const ObservationImageCarousel = memo(({ data = [], activeId, onClick, minHeight = 141 }: ObservationImageCarouselProps) => {
  return (
    <HorizontalScrollList sx={{ maxWidth: '100%', minHeight }} showNavigateButton={data.length > 4}>
      <HStack spacing={2}>
        {data.map(media => {
          const [speciesAnnotations, customLabelAnnotations, noteAnnotations] = classifyAnnotations(media.annotations)
          return (
            <ObservationImage
              isUploaded={media.isUploaded}
              isShowSpeciesLabel={!_.isEmpty(speciesAnnotations)}
              isShowNoteLabel={!_.isEmpty(noteAnnotations)}
              isShowCustomLabel={!_.isEmpty(customLabelAnnotations)}
              key={media.id}
              id={media.id}
              onClick={(_, blob) => onClick?.(media, blob)}
              bucketId={media.bucketId}
              src={media.filePath}
              sx={styles.image(activeId === media.id)}
            />
          )
        })}
      </HStack>
    </HorizontalScrollList>
  )
})

export default ObservationImageCarousel
