import { useContext, useMemo } from 'react'
import CheckpointOverviewContext from './CheckpointOverviewContext'
import { TCheckpointOverviewState } from './CheckpointOverviewState'
import _ from 'lodash'

export const getSelectedObservation = (state: TCheckpointOverviewState) => {
  return _.find(state.observations, { id: state.selectedObservationId })
}

export const getSelectedObservationMedia = (state: TCheckpointOverviewState) => {
  const selectedObservation = getSelectedObservation(state) ?? { mediaList: [] }
  const { mediaList = [] } = selectedObservation
  return _.find(mediaList, { id: state.selectedObservationMediaId })
}

const useCheckpointOverviewSelector = <T,>(selector: (state: TCheckpointOverviewState) => T): T => {
  const context = useContext(CheckpointOverviewContext)

  if (!context) {
    throw new Error('useCheckpointOverviewContext must be used within a CheckpointOverviewProvider')
  }

  const { state } = context

  return useMemo(() => selector(state), [state, selector])
}

export default useCheckpointOverviewSelector
