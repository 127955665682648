import { ButtonProps, Button } from '@components/atom'
import { styled } from '@mui/material/styles'

export const ActionButtonStyled = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: '10px',
  borderRadius: 8,
  borderColor: theme.palette.grey[300],
  backgroundColor: 'white',
  color: theme.palette.grey[700],
  '&:hover': {
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[200],
  },
}))
