import { forwardRef } from 'react'

import { ButtonGroup, ButtonProps } from '@components/atom'
import Icons from '@components/atom/Icons'
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined'
import ZoomInMapOutlinedIcon from '@mui/icons-material/ZoomInMapOutlined'
import { useTheme } from '@mui/material'

import Render from '@components/atom/Render/Render'
import { ActionButtonStyled } from './ToggleCollapseMap.style'

export type ToggleCollapseMapProps = {
  toggleActions: () => void
  isCollapse: boolean
  buttonsProps?: ButtonProps
  iconSize?: 'small' | 'medium' | 'large' | 'inherit' | undefined
}

const ToggleCollapseMap = forwardRef(({ toggleActions, isCollapse, iconSize = 'medium', buttonsProps = {} }: ToggleCollapseMapProps, _) => {
  const { palette } = useTheme()

  return (
    <ButtonGroup variant="outlined" aria-label="labeling-toolbar">
      <ActionButtonStyled buttonSize="auto" {...buttonsProps} onClick={toggleActions}>
        <Render
          test={isCollapse}
          fallback={
            <Icons
              Icon={() => <ZoomInMapOutlinedIcon fontSize={iconSize} sx={{ transform: 'rotate(90deg)' }} />}
              color={palette.grey[700]}
            />
          }
        >
          <Icons
            Icon={() => <ZoomOutMapOutlinedIcon fontSize={iconSize} sx={{ transform: 'rotate(90deg)' }} />}
            color={palette.grey[700]}
          />
        </Render>
      </ActionButtonStyled>
    </ButtonGroup>
  )
})

export default ToggleCollapseMap
