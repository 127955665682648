import React, { forwardRef } from 'react'
import { BoxProps } from '../Box'
import { StyledOlMap } from './OlMap.styles'

interface OlMapProps {
  children?: React.ReactNode
  isFullScreen?: boolean
  sx?: BoxProps['sx']
}

const OlMap = forwardRef<HTMLDivElement, OlMapProps>(({ children, sx, ...props }, ref) => {
  return (
    <StyledOlMap {...props} width="100vw" height="100vh" position="absolute" sx={sx}>
      <div ref={ref} className="map" />
      {children}
    </StyledOlMap>
  )
})

export default OlMap
