import { Feature, Map } from 'ol'
import { Geometry } from 'ol/geom'
import VectorSource from 'ol/source/Vector'

import { FeatureProperties } from './open-layer.styles'
import ENV from '@configs/env'

export const getTileUrl = (tileCoord: any[], bucketId: string, folderPath: string) => {
  const z = tileCoord[0]
  const x = tileCoord[1]
  const y = Math.pow(2, z) - 1 - tileCoord[2]
  return `${ENV.SUPABASE_URL}/storage/v1/object/authenticated/${bucketId}/${folderPath}/${z}/${x}/${y}.png`
}

export const isLayerExist = (map: any, layer: any) => {
  return map.getLayers().getArray().includes(layer)
}

interface ZoomToFeatureParams {
  map: Map
  feature?: Feature<Geometry>
}

export const zoomToSurveyFeature = ({ map, feature }: ZoomToFeatureParams) => {
  if (!feature) return
  const geometry = feature?.getGeometry()
  const extent = geometry?.getExtent()
  if (extent) {
    map.getView().fit(extent, { size: map.getSize(), padding: [100, 100, 100, 100], duration: 300, maxZoom: 22 })
  }
}

export const zoomToCheckpointFeature = ({ map, feature }: ZoomToFeatureParams) => {
  if (!feature) return
  const geometry = feature?.getGeometry()
  const extent = geometry?.getExtent()
  if (extent) {
    map.getView().fit(extent, { size: map.getSize(), padding: [100, 100, 100, 100], duration: 300, maxZoom: 16 })
  }
}

export const zoomToSource = (map: Map, source: VectorSource<Feature<Geometry>>) => {
  const extent = source.getExtent()
  if (extent) {
    map.getView().fit(extent, { size: map.getSize(), padding: [100, 100, 100, 100], duration: 0 })
  }
}

export const hideAllFeatureInSource = (vectorSource?: VectorSource<Feature<Geometry>>) => {
  if (vectorSource) {
    vectorSource.forEachFeature(feature => {
      feature.set(FeatureProperties.Visible, false)
      feature.set(FeatureProperties.Selected, false)
    })
  }
}

export const displayAllFeatureInSource = (vectorSource?: VectorSource<Feature<Geometry>>) => {
  if (vectorSource) {
    vectorSource.forEachFeature(feature => feature.set(FeatureProperties.Visible, true))
  }
}

export const disableAllFeatureInSource = (vectorSource?: VectorSource<Feature<Geometry>>) => {
  if (vectorSource) {
    vectorSource.forEachFeature(feature => feature.set(FeatureProperties.Disabled, true))
  }
}

export const enableAllFeatureInSource = (vectorSource?: VectorSource<Feature<Geometry>>) => {
  if (vectorSource) {
    vectorSource.forEachFeature(feature => feature.set(FeatureProperties.Disabled, false))
  }
}

export const setEditingAllFeatureInSource = (vectorSource?: VectorSource<Feature<Geometry>>) => {
  if (vectorSource) {
    vectorSource.forEachFeature(feature => feature.set(FeatureProperties.Editing, true))
  }
}
