import { Chip } from '@components/atom'
import { chipClasses, styled, SxProps } from '@mui/material'
import { CSSProperties } from 'react'

export const collapseToolbar: SxProps = {
  position: 'absolute',
  bottom: 16,
  right: 16,
  backgroundColor: 'teal.100',
}

export const StyledChip = styled(Chip)(({ theme }) => ({
  borderColor: theme.palette.teal?.[100],
  [`&.${chipClasses.outlined}`]: {
    borderColor: theme.palette.teal?.[600],
    color: theme.palette.teal?.[600],
    background: theme.palette.background.paper,
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.background.paper,
    },
  },
  [`&.${chipClasses.filled}`]: {
    backgroundColor: theme.palette.teal?.[600],
    color: 'white',
  },
}))

export const displayOptions: SxProps = {}

export const mapWrapper: SxProps = {
  position: 'relative',
  margin: '0 auto',
}

export const mapContainer: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
}

export const legendWrapper = {
  position: 'absolute',
  left: '6px',
  top: '20px',
}
