import { useAppLoadingContext } from '@context/AppLoading'
import useAppParams from '@hooks/useAppParams'
import { LabelDto } from '@models/label.model'
import labelService from '@services/LabelService/LabelService'
import { useQuery } from '@tanstack/react-query'
import { useRequest } from 'ahooks'
import { useEffect, useMemo } from 'react'

function useQuerySearchLabels({ projectId, labelSetId }: { projectId?: string; labelSetId?: string | null }) {
  const { setLoading } = useAppLoadingContext()
  const { projectId: projectIdParam = '' } = useAppParams()

  const projId = projectId ?? projectIdParam

  const { loading, run, data, ...rest } = useRequest(
    searchValue => labelService.searchLabels({ searchValue, labelSetId, projectId: projId }),
    {
      debounceWait: 300,
      ready: !!projId,
    },
  )

  const {
    isLoading: loadingLabelCustom,
    data: labelsResponse,
    refetch,
  } = useQuery({
    queryKey: ['fetch-labels-list', projId],
    queryFn: () => labelService.fetchCustomLabels({ projectId: projId || '' }),
    enabled: !!projId,
    refetchOnMount: false,
  })

  useEffect(() => {
    setLoading(prev => ({
      ...prev,
      labels: loading,
    }))
  }, [loading])

  const labels: LabelDto[] = useMemo(() => data?.data ?? [], [data])
  const listLabels: LabelDto[] = useMemo(() => labelsResponse?.data ?? [], [labelsResponse])

  return { labels, search: run, loading, ...rest, listLabels, loadingLabelCustom, refetch }
}

export default useQuerySearchLabels
