import { FormCategory, FormResult, FormSurvey } from './formCatalog.model'

export enum EFormSubmissionState {
  Open = 'open',
  InProgress = 'in_progress',
  Complete = 'complete',
}

export interface IForms {}

export interface IFormSubmissionData {
  id: string
  name: string
  version: string
  forms: { [key: string]: FormSurvey }
  formCategory: FormCategory
  formResult: FormResult | null
}

export interface IFormSubmission {
  id: string
  userId?: string
  surveyId: string
  projectId: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  state: EFormSubmissionState
  formData: IFormSubmissionData
}

export interface IFormCreationData {
  id: string
  name: string
  version: string
}

export interface IFormCreation {
  surveyId: string
  projectId: string
  state: EFormSubmissionState
  formData: IFormCreationData
}
