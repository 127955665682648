import { Box, styled } from '@mui/material'
import { BoxProps } from '../Box'

interface StyledOlMapProps extends BoxProps {}

export const StyledOlMap = styled(Box)<StyledOlMapProps>(({}) => ({
  '& .map': {
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
}))
