import { ReportMap, ReportMapData, ReportMapDisplayOption } from '@models/reportResultDetail.model'
import _ from 'lodash'

export const getDisplayOptionByKey = (mapData: ReportMap, stylingOption: string) => {
  return mapData?.displayOptions?.find((option: ReportMapDisplayOption) => option.key === stylingOption)
}

export const calculateGradientValues = (ReportMapData: ReportMapData[], stylingOption: string) => {
  const values = ReportMapData.map(a => a[stylingOption])
  const min = Math.min(...values)
  const max = Math.max(...values)
  const mid = max - min > 10 ? Math.round((min + max) / 2) : null

  return { minValue: min, maxValue: max, midValue: mid }
}

export const mapCategoryValues = (data: ReportMapData[], key: string) => {
  return _.uniq(_.map(data, item => item[key])).sort()
}
