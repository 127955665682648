export enum ERoutePaths {
  Home = '/',
  Login = '/login',
  Report = '/report',
  Reports = '/reports',
  Projects = '/projects',
  ProjectDetail = '/:projectId',
  ListSurvey = '/:projectId/list-survey',
  CreateSurvey = '/:projectId/list-survey/create-survey',
  EditSurvey = '/:projectId/list-survey/edit-survey',
  SurveyDetail = '/:projectId/survey-detail',
  CheckpointOverview = '/:projectId/checkpoint-overview',
  NotFound = '/404',
}

export default ERoutePaths
