import { Stack, Typography } from '@components/atom'
import Icons from '@components/atom/Icons'
import { MozaicLogo } from '../../../icons'
import * as styles from './BrandLogo.styles'

export type BrandLogoProps = {
  logoOnly?: false
  onClick?: () => void
}

export const BrandLogo = ({ onClick }: BrandLogoProps) => {
  return (
    <Stack sx={styles.brandLogoContainer(onClick)} onClick={onClick}>
      <Icons Icon={MozaicLogo} width={'44px'} height={'44px'} />
      <Stack spacing={0.5}>
        <Typography sx={styles.brandLogoTextContainer(true)}>MOZAIC</Typography>
        <Typography sx={styles.brandLogoTextContainer(false)}>EARTH</Typography>
      </Stack>
    </Stack>
  )
}

export default BrandLogo
