import { SxProps } from '@mui/material'

export const container: SxProps = {
  width: '100%',
  backgroundColor: 'teal.100',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '24px 32px',
  borderRadius: '8px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: 'teal.200',
  },
}
export const nameGroup: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  maxWidth: '30%',
}
export const name: SxProps = {
  fontSize: '28px',
  fontWeight: 500,
  marginBottom: '8px',
  color: 'teal.800',
  lineHeight: '32px',
}
export const subtitle: SxProps = {
  fontSize: '19px',
  color: 'teal.800',
  lineHeight: '22.27px',
}
export const infoGroup: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  flex: 2,
}
