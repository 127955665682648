import useMozaicNavigation from '@hooks/useMozaicNavigate/useMozaicNavigate.hook'
import useMozaicSnackbar from '@hooks/useMozaicSnackbar/useMozaicSnackbar.hook'
import authService from '@services/AuthService/AuthService'
import { TVerifyOtpResponseSuccess } from '@services/AuthService/AuthService.models'
import { useMutation } from '@tanstack/react-query'
import { useContext, useEffect, useRef } from 'react'
import AuthenticationContext from './AuthenticationContext'
import { ACTIONS } from './AuthenticationContext.const'
import { IUserInfo } from './AuthenticationState'
import useAuthenticationSelector from './useAuthenticationSelector'
import { AuthChangeEvent, Session } from '@supabase/supabase-js'
import supabaseClient from '@utils/supabase/MozaicSupebaseClient'

interface UseAuthenticationProps {
  onSendMailSuccess?: (data: any) => void
  onVerifyOtpSuccess?: () => void
  onSendMailFailed?: (error: Error) => void
  onVerifyOtpFailed?: (error: Error) => void
  onAuthStateChange?: (event: AuthChangeEvent, session: Session | null) => void | Promise<void>
}

export const useAuthentication = ({
  onSendMailSuccess,
  onVerifyOtpSuccess,
  onSendMailFailed,
  onVerifyOtpFailed,
  onAuthStateChange,
}: UseAuthenticationProps = {}) => {
  const context = useContext(AuthenticationContext)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  if (!context) {
    throw new Error('AuthenticationContext must be used within an AuthenticationProvider')
  }

  const { dispatch } = context
  const { navigateToLoginPage } = useMozaicNavigation()
  const { enqueueErrorSnackbar } = useMozaicSnackbar()
  const mail = useRef('')

  const user = useAuthenticationSelector(state => state.user)

  useEffect(() => {
    if (!!onAuthStateChange) {
      const {
        data: { subscription },
      } = supabaseClient.auth.onAuthStateChange(onAuthStateChange)

      return () => {
        subscription.unsubscribe()
        if (intervalRef.current) {
          clearInterval(intervalRef.current)
        }
      }
    }
  }, [])

  const updateUserInfo = (user?: IUserInfo) => {
    dispatch({
      type: ACTIONS.SET_USER,
      payload: { user },
    })
  }

  const handleLoginSuccess = (res: TVerifyOtpResponseSuccess) => {
    updateUserInfo({ email: res.user?.email, id: res?.user?.id })
    onVerifyOtpSuccess?.()
  }

  const signInMutation = useMutation({
    mutationFn: authService.loginWithEmailOtp,
    onSuccess: (data, email) => {
      mail.current = email
      updateUserInfo(data)
      onSendMailSuccess?.(data)
    },
    onError: onSendMailFailed,
  })

  const verifyOtpMutation = useMutation({
    mutationFn: (otp: string) => authService.verifyOtp({ email: mail.current, otp }),
    onSuccess: data => {
      handleLoginSuccess?.(data)
    },
    onError: onVerifyOtpFailed,
  })

  const onLogoutSuccess = () => {
    updateUserInfo(undefined)
    navigateToLoginPage()
  }

  const logoutMutation = useMutation({
    mutationFn: authService.logout,
    onSuccess: onLogoutSuccess,
  })

  const logout = async ({ type = 'manual' }: { type?: 'auto' | 'manual' } = {}) => {
    await logoutMutation.mutateAsync()
    updateUserInfo(undefined)
    navigateToLoginPage()
    if (type === 'auto') {
      enqueueErrorSnackbar("You've been logged out!")
    }
  }

  const resendOtpMutation = useMutation({
    mutationFn: () => authService.loginWithEmailOtp(mail.current),
  })

  return {
    user,
    /**
     * Verify Otp
     */
    verifyOtpMutation,
    verifyOtpLoading: verifyOtpMutation.isPending,
    /**
     * Send mail, sign in
     */
    signInMutation,
    signInLoading: signInMutation.isPending,
    resendOtpMutation,
    /**
     * Logout
     */
    logoutMutation,
    logout,
    logoutLoading: logoutMutation.isPending,
    onLogoutSuccess,
    /**
     * Init logged in user
     */
    updateUserInfo,
  }
}

export default useAuthentication
