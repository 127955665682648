import { Stack } from '@components/atom'
import { SxProps, styled } from '@mui/material'

export const LegendGradientContainer = styled(Stack)(({ theme }) => ({
  background: theme.palette.teal?.[100],
  padding: '10px 40px 10px 3px',
}))

export const Gradient: SxProps = {
  width: 20,
  height: 250,
  background: `linear-gradient(to top, rgb(0, 0, 128), rgb(173, 216, 230))`,
  position: 'relative',
  justifyContent: 'space-between',
  alignItems: 'center',
}

export const MinValue: SxProps = {
  position: 'absolute',
  bottom: 0,
  transform: 'translateX(25px)',
}

export const MidValue: SxProps = {
  position: 'absolute',
  top: '50%',
  transform: 'translateX(25px) translateY(-50%)',
}

export const MaxValue: SxProps = {
  position: 'absolute',
  top: 0,
  transform: 'translateX(25px)',
}
