import { FeatureLike } from 'ol/Feature'
import { Fill, Icon, Stroke, Style, Text } from 'ol/style'

import CPMarkerDisabled from '@assets/images/checkpoint/checkpoint-disabled.png'
import CPMarkerOutstanding from '@assets/images/checkpoint/checkpoint-outstanding.png'
import CPMarkerSelected from '@assets/images/checkpoint/checkpoint-selected.png'
import light from '@styles/theme/light'

const { mapPalette } = light.palette

export const createPolygonStyle = ({
  visible = false,
  disabled = false,
  isSelected = false,
  isHovered = false,
  editing = false,
  transparentBg = false,
} = {}) => {
  let fillColor = mapPalette.polygonFillColor.default
  let strokeColor = mapPalette.polygonStrokeColor.default
  let strokeWidth = mapPalette.strokeWidth.default

  if (isHovered) {
    fillColor = mapPalette.polygonFillColor.hover
    strokeColor = mapPalette.polygonStrokeColor.hover
    strokeWidth = mapPalette.strokeWidth.large
  }
  if (isSelected) {
    fillColor = mapPalette.polygonFillColor.selected
    strokeColor = mapPalette.polygonStrokeColor.selected
    strokeWidth = mapPalette.strokeWidth.large
  }
  if (disabled) {
    fillColor = mapPalette.polygonFillColor.disabled
    strokeColor = mapPalette.polygonStrokeColor.disabled
    strokeWidth = mapPalette.strokeWidth.default
  }
  if (editing) {
    fillColor = mapPalette.polygonFillColor.editing
    strokeColor = mapPalette.polygonStrokeColor.editing
    strokeWidth = mapPalette.strokeWidth.large
  }
  if (transparentBg) {
    fillColor = 'rgba(255, 255, 255, 0.0)'
  }
  const polygonStyle = new Style({
    fill: visible ? new Fill({ color: fillColor }) : undefined,
    stroke: visible ? new Stroke({ color: strokeColor, width: strokeWidth }) : undefined,
  })

  return polygonStyle
}

export const createLineStyle = ({ visible = false, disabled = false, isSelected = false, isHovered = false, editing = false } = {}) => {
  let lineWidth = mapPalette.lineWidth.large
  let strokeColor = mapPalette.lineStrokeColor.default

  if (isHovered) {
    lineWidth = mapPalette.lineWidth.large
    strokeColor = mapPalette.lineStrokeColor.hover
  }
  if (isSelected) {
    lineWidth = mapPalette.lineWidth.large
    strokeColor = mapPalette.lineStrokeColor.selected
  }
  if (disabled) {
    lineWidth = mapPalette.lineWidth.default
    strokeColor = mapPalette.lineStrokeColor.disabled
  }
  if (editing) {
    lineWidth = mapPalette.lineWidth.large
    strokeColor = mapPalette.lineStrokeColor.editing
  }
  const polygonStyle = new Style({
    stroke: visible
      ? new Stroke({
          color: strokeColor,
          width: lineWidth,
        })
      : undefined,
  })

  return polygonStyle
}

export enum FeatureProperties {
  Hover = 'mz-hover',
  Selected = 'mz-selected',
  Visible = 'mz-visible',
  Disabled = 'mz-disabled',
  Editing = 'mz-editing',
  TransparentBackGround = 'mz-transparent-bg',
  Linked = 'isLinked',
}

export const styleFunctionSurveys = (feature: FeatureLike): Style | Style[] | void => {
  const isHovered = feature.get(FeatureProperties.Hover)
  const isSelected = feature.get(FeatureProperties.Selected)
  const visible = feature.get(FeatureProperties.Visible)
  const disabled = feature.get(FeatureProperties.Disabled)
  const editing = feature.get(FeatureProperties.Editing)
  const transparentBg = feature.get(FeatureProperties.TransparentBackGround)

  const geometryType = feature.getGeometry()?.getType()
  const id = feature.get('plotKey')

  const labelStyle = new Style({
    text: new Text({
      font: '13px Calibri,sans-serif',
      fill: new Fill({
        color: '#000',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
      overflow: true,
    }),
  })
  if (visible && !editing && !disabled) {
    labelStyle.getText()!.setText(id)
  }

  switch (geometryType) {
    case 'Polygon':
    case 'MultiPolygon':
      const polygonStyle = createPolygonStyle({ disabled, isHovered, isSelected, visible, editing, transparentBg })
      return [labelStyle, polygonStyle]
    case 'LineString':
    case 'MultiLineString':
      const lineStyle = createLineStyle({ disabled, isHovered, isSelected, visible, editing })
      return [labelStyle, lineStyle]
    default:
      return [new Style(), new Style()]
  }
}

export const styleFunctionCheckpoints = (feature: FeatureLike): Style | Style[] | void => {
  const isSelected = feature.get(FeatureProperties.Selected)
  const isLinked = feature.get(FeatureProperties.Linked)
  const visible = feature.get(FeatureProperties.Visible)
  const geometryType = feature.getGeometry()?.getType()

  let src
  if (isSelected) {
    src = CPMarkerSelected
  } else if (isLinked) {
    src = CPMarkerOutstanding
  } else {
    src = CPMarkerDisabled
  }

  if (geometryType === 'Point' || geometryType === 'MultiPoint') {
    return new Style({
      image: new Icon({
        src: src,
        rotateWithView: true,
        scale: visible ? 0.7 : 0,
      }),
      zIndex: isSelected ? 2 : 1,
    })
  }
}
