import { Children, ReactElement } from 'react'

type EachProps<T> = {
  render: (item: T, index: number) => ReactElement | null
  of: T[] | undefined | null
  useArray?: boolean
}

export default function EachComponent<T>({ render, of, useArray = false }: EachProps<T>) {
  if (!of) {
    return null
  }

  return useArray ? Children.toArray(of.map((item, index) => render(item, index))) : of.map((item, index) => render(item, index))
}
