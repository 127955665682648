import { EObservationState } from '@models/observation.model'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { memo } from 'react'

export type SurveyObservationStateIconProps = {
  state: EObservationState
  allowMaskAsDone?: boolean
  onClickMaskAsDone?: () => void
  isLoadingAction?: boolean
}

export const SurveyObservationStateIcon: Record<EObservationState, React.ReactNode> = {
  [EObservationState.Done]: <CheckCircleRoundedIcon fontSize="small" color="success" />,
  [EObservationState.Open]: <ArrowForwardRoundedIcon fontSize="small" color="info" />,
  [EObservationState.InProgress]: <MoreHorizIcon fontSize="small" color="warning" />,
}

export const SurveyObservationActionIcon = memo(({ state }: SurveyObservationStateIconProps) => {
  return SurveyObservationStateIcon[state]
})

export default SurveyObservationActionIcon
