import { FormCategory, FormResult, FormSurvey } from '@models/formCatalog.model'
import { createContext, ReactNode, useState } from 'react'

interface FormSurveyEvaluationProviderProps {
  children: ReactNode
  defaultSchema?: FormSurvey | null
}

export interface FormSurveyEvaluationContextType {
  selectedForm: FormSurvey | null
  setSelectedForm: React.Dispatch<React.SetStateAction<FormSurvey | null>>
  formCategoryData: FormCategory | undefined
  setFormCategoryData: React.Dispatch<React.SetStateAction<FormCategory | undefined>>
  formResultData: FormResult | null
  setFormResultData: React.Dispatch<React.SetStateAction<FormResult | null>>
}

export const FormSurveyEvaluationContext = createContext<FormSurveyEvaluationContextType | null>(null)

const FormSurveyEvaluationProvider: React.FC<FormSurveyEvaluationProviderProps> = ({ defaultSchema = null, children }) => {
  const [selectedForm, setSelectedForm] = useState<FormSurvey | null>(defaultSchema)
  const [formCategoryData, setFormCategoryData] = useState<FormCategory | undefined>()
  const [formResultData, setFormResultData] = useState<FormResult | null>(null)

  return (
    <FormSurveyEvaluationContext.Provider
      value={{
        selectedForm: selectedForm,
        setSelectedForm: setSelectedForm,
        formCategoryData: formCategoryData,
        setFormCategoryData: setFormCategoryData,
        formResultData: formResultData,
        setFormResultData: setFormResultData,
      }}
    >
      {children}
    </FormSurveyEvaluationContext.Provider>
  )
}

export { FormSurveyEvaluationProvider }
