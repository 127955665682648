import React, { Suspense, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import { PageLoading } from '@components/molecule'
import { useAuthentication } from '@context/AuthenticationContext'
import useActivityTracker from '@hooks/useActivityTracker'

export const PrivateRoute = ({ title, children }: { title: string; children: any }) => {
  const { onLogoutSuccess, logout, updateUserInfo } = useAuthentication({
    onAuthStateChange: (_event, session) => {
      if (!session) {
        onLogoutSuccess()
      } else if (session) {
        updateUserInfo({ email: session?.user?.email, id: session?.user?.id })
      }
      if (session && isLoading) {
        setIsLoading(false)
      }
    },
  })
  const [isLoading, setIsLoading] = useState(true)

  useActivityTracker({
    onTimeout: () => logout({ type: 'auto' }),
    timeoutInMinutes: 60,
  })

  return (
    <>
      {isLoading && <PageLoading name="private-route" />}
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Suspense fallback={null}>{children}</Suspense>
    </>
  )
}

export default React.memo(PrivateRoute)
