import { VectorLayerName } from './ProjectDetailProvider'

export const ACTIONS = {
  SET_PROJECT_DETAIL: 'SET_PROJECT_DETAIL',
  SET_LIST_SURVEYS: 'SET_LIST_SURVEYS',
  SELECT_TILE_LAYER: 'SELECT_TILE_LAYER',
  SET_PROJECT_SURVEY_CHECKPOINTS: 'SET_PROJECT_SURVEY_CHECKPOINTS',
  SET_METRICS: 'SET_METRICS',
  SET_OBSERVATIONS: 'SET_OBSERVATIONS',
  SET_MAP_RENDER_COMPLETE: 'SET_MAP_RENDER_COMPLETE',
  SET_SELECTED_TILE: 'SET_SELECTED_TILE',
  SET_TILE_LAYERS: 'SET_TILE_LAYERS',
  SET_EVENTS: 'SET_EVENTS',
}

export const PROJECTION = {
  GEOGRAPHIC_COORDINATES: 'EPSG:4326',
  WEB_MERCATOR: 'EPSG:3857',
}

export const ZIndex: Record<VectorLayerName, number> = {
  surveys: 1,
  checkpoints: 2,
  createSurvey: 3,
  editSurvey: 3,
}
