import { ACTIONS } from './AuthenticationContext.const'
import { TAuthState } from './AuthenticationState'

function AuthenticationProducer(state: TAuthState, action: any): TAuthState {
  switch (action.type) {
    case ACTIONS.SET_USER: {
      return { ...state, user: action.payload.user }
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}

export default AuthenticationProducer
