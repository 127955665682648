import { Box as MuiBox, BoxProps as MuiBoxProps } from '@mui/material'

export type BoxProps = {
  children?: React.ReactNode
  alt?: string
  src?: string
} & MuiBoxProps

const Box = ({ children, ...rest }: BoxProps) => {
  return <MuiBox {...rest}>{children}</MuiBox>
}

export default Box
